import React from 'react';
import { FormLabel, CleaveInput } from '@common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import { CleaveOptions } from 'cleave.js/options';
import useAutoSave from '@hooks/useAutoSave';
import FormError from '@components/form/FormErrorComponent';

export type CleaveInputComponentProps = {
    control: Control<any>;
    name: string;
    options: CleaveOptions;
    label?: string | React.ReactNode;
    type?: string;
    placeholder?: string;
    returnRawValue?: boolean;
    rules?: RegisterOptions;
    ddMaskText?: boolean;
    showError?: boolean;
    readOnly?: boolean;
    autoComplete?: string;
    inputMode?: 'search' | 'text' | 'email' | 'none' | 'tel' | 'url' | 'numeric' | 'decimal';
    required?: boolean;
};

const CleaveInputComponent = ({
    control,
    name = '',
    rules = undefined,
    placeholder = '',
    returnRawValue = true,
    options,
    type = 'text',
    label = '',
    ddMaskText = false,
    showError = true,
    readOnly = false,
    autoComplete = undefined,
    inputMode = 'text',
    required = true,
}: CleaveInputComponentProps) => {
    const { handleSave } = useAutoSave();
    const cleaveInputProps = ddMaskText ? { 'data-dd-privacy': 'mask' } : {};

    return (
        <Controller
            name={name}
            key={name}
            control={control}
            defaultValue=""
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error, invalid, isDirty } }) => (
                <>
                    {label ? <FormLabel>{label}</FormLabel> : null}
                    <CleaveInput
                        onBlur={(event) => isDirty && !invalid && handleSave({ name, value: event.currentTarget.value })}
                        options={options}
                        className={required && error ? 'required-with-error' : ''}
                        onChange={(e) => onChange(returnRawValue ? e.target?.rawValue : e?.target?.value || '')}
                        name={name}
                        placeholder={placeholder}
                        type={type}
                        {...autoComplete && { autoComplete }}
                        value={value}
                        {...cleaveInputProps}
                        readOnly={readOnly}
                        inputMode={inputMode}
                    />
                    {showError && error?.message ? <FormError errorMsg={error.message} /> : null}
                </>
            )}
        />
    );
};

export default CleaveInputComponent;
