import { IOpportunityLineItem } from '@pppinc/salesforce/lib/interfaces/sobject';

/**
 * Merges values from two objects, giving precedence to the dominant object.
 *
 * This function combines key-value pairs from two objects. If a key exists in both
 * objects, the value from the dominant object will be used. If the value in the
 * dominant object is falsy, the value from the subordinate object will be used.
 *
 * @param {Object} subordinate - The object with values to be merged.
 * @param {Object} dominant - The object with values that take precedence.
 * @returns {Object} - A new object containing merged values.
 */
export function mergeValues(subordinate: any, dominant: any): any {
    const result = subordinate;
    for (const key in dominant) {
        if (dominant[key]) {
            result[key] = dominant[key];
        }
    }
    return result;
}

export function getOpportunityLineItemsHelperText(opportunityLineItems: Partial<IOpportunityLineItem>[]): string[]  {
    const helperTexts = [];
    for (const opportunityLineItem of opportunityLineItems) {
        if (process.env.NEXT_PUBLIC_APP_ENV === 'app' && opportunityLineItem.Product2Id === '01tPj000003LkNtIAK'){ // clover platform fee
            helperTexts.push(`**Only applicable to non-swiped transactions processed on the Clover device`)

        } else if (opportunityLineItem.Product2Id === '01tO3000004W0HAIA0') { // clover platform fee
            helperTexts.push(`**Only applicable to non-swiped transactions processed on the Clover device`)
        }
    }
    return helperTexts;
}

/**
 * Truncates a file name to a specified maximum length while preserving the file extension.
 *
 * @param {string} name - The file name to be truncated.
 * @param [maxLength=79] - The maximum length of the truncated file name.
 * @returns {string} - The truncated file name.
 */
export function truncateFileName(name: string, maxLength = 79): string {
    if (name.length <= maxLength) return name;
    // shorten the name to maxLength chars (including the extensions) while keeping all extensions
    const firstDotIndex = name.indexOf('.');
    if (firstDotIndex !== -1 && name.length - firstDotIndex >= 79) {
        // return last maxLength chars
        let fileName = name.substring(name.length - maxLength);
        while (fileName.startsWith('.')) {
            fileName = fileName.slice(1);
        }
        return fileName;
    }
    const baseName = name.slice(0, firstDotIndex);
    const extensions = name.slice(firstDotIndex);
    const allowedBaseNameLength = maxLength - extensions.length;
    const shortenedBaseName = baseName.slice(0, allowedBaseNameLength);
    return shortenedBaseName + extensions;
}
