"use client";

import React, { type SyntheticEvent } from 'react';
import { FormLabel, TextInput } from '@common';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import useAutoSave from '@hooks/useAutoSave';
import FormError from '@components/form/FormErrorComponent';

export type TextInputComponentProps = {
    control: Control<any>;
    name: string;
    label?: string | React.ReactNode;
    type?: string;
    className?: string;
    isTextArea?: boolean;
    rows?: number;
    autoSave?: boolean;
    placeholder?: string;
    showError?: boolean;
    rules?: RegisterOptions;
    disabled?: boolean;
    autoComplete?: string;
    border?: string;
    inputMode?: string;
    autoFocus?: boolean;
    required?: boolean;
};

const TextInputComponent = (props: Partial<TextInputComponentProps>) => {
    const {
        control,
        name = '',
        rules = undefined,
        placeholder = '',
        isTextArea = false,
        autoSave = true,
        rows = 3,
        type = 'text',
        showError = true,
        label,
        className,
        disabled = false,
        autoComplete = undefined,
        required = true,
        border = undefined,
        inputMode = undefined,
        autoFocus = false,
    } = props;
    const { handleSave } = useAutoSave();

    return (
        <Controller
            name={name}
            key={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value, ref }, fieldState: { error, invalid } }) => (
                <>
                    {label ? <FormLabel>{label}</FormLabel> : null}
                    <TextInput
                        as={isTextArea ? ('textarea' as never) : undefined}
                        className={required && error
                            ? className
                                ? className?.concat(' required-with-error')
                                : 'required-with-error'
                            : className}
                        disabled={disabled}
                        onBlur={({ currentTarget: { value: currentValue } }: SyntheticEvent<HTMLInputElement>) =>
                            autoSave && !invalid && handleSave({ name, value: currentValue })}
                        ref={ref}
                        onChange={onChange}
                        name={name}
                        rows={rows}
                        {...autoComplete && { autoComplete }}
                        placeholder={placeholder}
                        type={type}
                        value={value || ''}
                        border={border}
                        inputMode={inputMode}
                        autoFocus={autoFocus}
                    />
                    {showError && error?.message ? <FormError errorMsg={error.message} /> : null}
                </>
            )}
        />
    );
};

export default TextInputComponent;
