import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import useAutoSave from '@hooks/useAutoSave';

import FileInput from '@common/inputs/FileInput';
import FormError from '@components/form/FormErrorComponent';

export type FileInputComponentProps = {
    control: Control<any>;
    name: string;
    placeholder?: string;
    rules?: RegisterOptions;
    showError?: boolean;
    inputModel?: 'file' | 'photoId'
    required?: boolean;
};

const FileInputComponent = ({
    control,
    name = '',
    rules = undefined,
    placeholder = '',
    showError = true,
    required = true,
    inputModel = 'file'
}: FileInputComponentProps) => {
    const { handleSave } = useAutoSave();

    return (
        <Controller
            name={name}
            key={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <>
                    <FileInput
                        name={name}
                        onBlur={e => !invalid && handleSave({ name, value: e?.url })}
                        onChange={onChange}
                        placeholder={placeholder}
                        inputModel={inputModel}
                        value={value}
                        className={required && error ? 'required-with-error' : ''}
                    />
                    {showError && error?.message ? <FormError errorMsg={error.message} /> : null}
                </>
            )}
        />
    );
};

export default FileInputComponent;
