import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap'
import Image from 'next/image';
import { mapFormValueToPfacAPI } from '@app/application/[id]/utils';
import { mapFormValuesToAPI as mapFormValuesToOpptyAPI } from '@app/opportunity/[id]/utils';
import InfoIcon from '@assets/info-icon.svg';
import TextComponent from '@common/typography/TextComponent';
import useWizardContext from './wizard/useWizardContext';
import useLocalStorage from './useLocalStorage';
import { AllFields } from './wizard/types';

const SaveContext = createContext<{
    addUpdate: (update: { [key: string]: any }) => void,
    isSaving: boolean,
}>({
    addUpdate: () => undefined,
    isSaving: false,
});
export const useSaveContext = () => useContext(SaveContext);

type State = {
    error: any,
    isRetrying: boolean,
    isSaving: boolean,
    queue: { [key: string]: any, _retries: number }[],
}
type Action =
    { type: 'add', update: { [key: string]: any } } |
    { type: 'retrying' } |
    { type: 'save' } |
    { type: 'complete' } |
    { type: 'error', error: any } |
    { type: 'offline' } |
    { type: 'online' };
// FIXME(LY) We will need to implement another version for Account API path since it uses partial updates and we can't abandon any updates
export const SaveContextProvider = ({ children = null, onAutoSave }: { children?: React.ReactNode, onAutoSave: (update: any) => Promise<void> }) => {
    const [{ error, isRetrying, isSaving, queue }, dispatch] = useReducer((state: State, action: Action) => {
        switch (action.type) {
            case 'add': {
                return { ...state, queue: [...state.queue, { ...action.update, _retries: 0 }] };
            }
            case 'save': {
                return { ...state, isSaving: true };
            }
            case 'complete': {
                return { ...state, error: false, isRetrying: false, isSaving: false, queue: state.queue.slice(1) };
            }
            case 'error': {
                const update = state.queue[state.queue.length - 1];
                // Only use last update if user has multiple since Oppty path sends entire form object
                return { ...state, error: action.error, isRetrying: false, queue: [update] };
            }
            case 'retrying': {
                return { ...state, isRetrying: true}
            }
            case 'offline': {
                // Retrying here is waiting for the window online event to trigger
                return { ...state, error: true, isRetrying: true };
            }
            case 'online': {
                return { ...state, error: false, isRetrying: false };
            }
            default: return { ...state };
        }
    }, { error: false, isRetrying: false, isSaving: false, queue: [] });

    useEffect(() => {
        const onOffline = () => dispatch({ type: 'offline' });
        const onOnline = () => dispatch({ type: 'online' });
        window.addEventListener('offline', onOffline);
        window.addEventListener('online', onOnline);
        return () => {
            window.removeEventListener('offline', onOffline);
            window.removeEventListener('online', onOnline);
        };
    }, []);

    const saveUpdates = useCallback(() => {
        const [update] = queue;
        onAutoSave(update)
            .then(() => {
                dispatch({ type: 'complete' });
            }).catch(e => {
                update._retries++;
                dispatch({ type: 'error', error: e });
            });
    }, [dispatch, queue, onAutoSave]);

    useEffect(() => {
        if (error && !isRetrying) {
            dispatch({ type: 'retrying' });
            (new Promise(resolve => { setTimeout(resolve, 1000) })).then(() => saveUpdates())
        }
        if (!isSaving && queue.length && !error) {
            dispatch({ type: 'save' });
            saveUpdates();
        }
    }, [dispatch, error, isRetrying, isSaving, queue, saveUpdates]);

    const value = useMemo(() => ({
        addUpdate: (update: { [key: string]: any }) => dispatch({ type: 'add', update }),
        isSaving,
    }), [dispatch, isSaving]);

    return <SaveContext.Provider value={value}>
        <Modal show={error}>
            <Modal.Body className='pa-2'>
                <Row>
                    <Image
                        src={InfoIcon}
                        width="40"
                        height="40"
                        alt="error-info"
                        className="cursor-pointer mt-4 mb-3"
                    />
                </Row>
                <Row>
                    <Col md={12} className="text-center">
                        <TextComponent fontSize='md' fontWeight='600'>
                            It appears you&apos;re offline.
                        </TextComponent>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4" md={12}>
                        <TextComponent>
                            Your latest entry failed to save. Any progress you&apos;ve made while being online prior to this message has been saved.
                        </TextComponent>
                        <TextComponent className="mt-2">
                            Your connection will be checked continuously. Once reconnected, your latest entry will be saved, and this notification will close automatically.
                        </TextComponent>
                        <TextComponent className="mt-2">
                            If you close the application and return once you&apos;re reconnected, the progress you&apos;ve made while online will be preserved.
                        </TextComponent>
                    </Col>
                    <Col className='mt-4 d-flex align-items-center justify-content-center'>
                        <Spinner
                            animation='border'
                            variant='secondary'
                            size='sm'
                        />
                        <TextComponent fontSize='sm' className='ms-2' color='secondary'>
                            Checking Connection...
                        </TextComponent>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        {children}
    </SaveContext.Provider>;
};

const useAutoSave = () => {
    const { form, id, isOpportunity, hasEIServerOrCloud, hasEmergepay } = useWizardContext();
    const { addUpdate } = useContext(SaveContext);

    const [, setLocalStorageValues] = useLocalStorage<{ [key: string]: any }>(id || 'default');

    const { getValues } = form;

    // FIXME(LY) Update this to handle multiple fields at once so we don't need 5 consecutive saves (ex: bank page)
    const handleSave = async ({ name, value }: { name: string, value: any }) => {
        // FIXME(LY) Would be nice if this was more targeted. Look at dot notations like Account API?
        setLocalStorageValues(getValues()); // overwrites local storage item with ALL current form values
        const applicationUpdate = isOpportunity
            ? mapFormValuesToOpptyAPI(getValues(), { hasEmergepay, hasEIServerOrCloud })
            : mapFormValueToPfacAPI(name as any, value, getValues() as unknown as AllFields);
        if (id && Object.keys(applicationUpdate).length) {
            addUpdate(applicationUpdate);
        }
    };

    return { handleSave };
};

export default useAutoSave;
