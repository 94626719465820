import { Button, TextInput } from '@common';
import FileUploadIcon from '@components/icons/FileUploadIcon';
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import useWizardContext from '@hooks/wizard/useWizardContext';
import type { FileType } from '@hooks/types';
// eslint-disable-next-line import/no-cycle
import { IFile__c } from '@pppinc/salesforce/lib/interfaces/sobject';
import { truncateFileName } from '@utils/helperFunctions';
import FileInputPhotoID from './FileInputPhotoID';

export const AcceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'] as const;

type AcceptedFileType = (typeof AcceptedFileTypes)[number];

export const isAcceptedFileType = (type: AcceptedFileType): type is AcceptedFileType =>
    AcceptedFileTypes.includes(type);

export type DocumentFile = {
    id?: string;
    url: string;
    contentType: AcceptedFileType;
    name: string;
    formFieldName: string;
    type?: IFile__c['Des__c']
};

const FileMap: { [key: string]: FileType } = {
    primaryAccountFile: 'Voided Check',
    processingStatement1: 'Current Statement',
    processingStatement2: 'Current Statement',
    processingStatement3: 'Current Statement',
};

interface FileInputProps {
    name: string;
    value?: DocumentFile;
    onChange: (file: Omit<DocumentFile, 'type'> | null) => void;
    onBlur: (file?: Omit<DocumentFile, 'type'>) => void;
    placeholder: string;
    className?: string;
    inputModel?: 'file' | 'photoId';
}

const FileInput = ({
    name,
    value = undefined,
    onChange,
    onBlur,
    placeholder,
    className = '',
    inputModel = 'file',
}: FileInputProps) => {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { isOpportunity = false, onFileRemoval, onFileUpload, setLoading: setWizardLoading } = useWizardContext();
    const [selectedFile, setSelectedFile] = useState<string>();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const mobilePictureRef = React.useRef<HTMLInputElement>(null);

    const handleUploadClick = () => inputRef.current?.click();

    const handleTakePicture = () => mobilePictureRef.current?.click();

    useEffect(() => {
        if (name.includes('PhotoID')) {
            const storedFile = sessionStorage.getItem(`${name}-selectedFile`);
            if (storedFile) {
                const fileUrl = new URL(storedFile);
                fetch(fileUrl)
                    .then((response) => {
                        if (response.ok) {
                            setSelectedFile(storedFile);
                        }
                    })
                    .catch(() => {}); // catch error so it does not print error to console
            }
        }
    }, [name]);
    const handleFileSelection = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        if (e.target.files && e.target.files.length) {
            const fileValue = e.target.files[0];

            setLoading(true);
            setWizardLoading(true);

            if (fileValue.size > 25 * 1024 * 1024) {
                setLoading(false);
                setError('The file is too large. The maximum file size is 25 Megabyte');
                return;
            }

            if (!isAcceptedFileType(fileValue.type as AcceptedFileType)) {
                setLoading(false);
                setError('Unsupported file format');
                return;
            }
            setSelectedFile(URL.createObjectURL(fileValue));
            sessionStorage.setItem(`${name}-selectedFile`, URL.createObjectURL(fileValue));
            try {
                const upload = await onFileUpload(fileValue, truncateFileName(`${Date.now()}-${fileValue.name}`), FileMap[name] || 'Other');
                if (upload?.url) {
                    const formValue = {
                        name: truncateFileName(fileValue.name),
                        contentType: fileValue.type as AcceptedFileType,
                        formFieldName: name,
                        ...upload,
                    };
                    onChange(formValue);
                    onBlur(formValue);
                }
            } catch (err) {
                setSelectedFile(undefined);
                sessionStorage.removeItem(`${name}-selectedFile`);
                setError(err?.message || 'Failed to upload');
            }
            setLoading(false);
            setWizardLoading(false);
        }
    };

    const handleRemoveFile = async () => {
        setError('');
        try {
            if (value) {
                setLoading(true);
                setWizardLoading(true);
                await onFileRemoval(value.url.split('/').pop() as string);
            }
            if (inputRef?.current?.value) {
                inputRef.current.value = '';
            }
            if (mobilePictureRef?.current?.value) {
                mobilePictureRef.current.value = '';
            }
            onChange(null);
            onBlur(undefined);
            if (selectedFile) {
                URL.revokeObjectURL(selectedFile);
            }
            setSelectedFile(undefined);
            sessionStorage.removeItem(`${name}-selectedFile`);
        } catch (e) {
            setError(e.message || 'Failed to remove');
        } finally {
            setLoading(false);
            setWizardLoading(false);
        }
    };

    const getEventComponent = () => {
        if (loading) {
            return <Spinner animation='border' variant='secondary' />;
        }

        if (value) {
            return (
                <>
                    {/* FIXME(LY) Only shows this for Oppty as with Application, we need to delete the existing file */}
                    {isOpportunity && (
                        <Button variant='secondary' className='my-2 me-1' onClick={handleUploadClick}>
                            Change
                        </Button>
                    )}
                    <Button variant='danger' className='my-2' onClick={handleRemoveFile}>
                        Remove
                    </Button>
                </>
            );
        }

        return <FileUploadIcon onClick={handleUploadClick} className='my-2 cursor-pointer' />;
    };
    return (
        <>
            <Row className='align-items-center file-input'>
                <Col lg={value ? 8 : 11} md={value ? 10 : 11} xs={value ? 12 : 10}>
                    <input
                        id={`${name}-input`}
                        className='d-none'
                        ref={inputRef}
                        onChange={handleFileSelection}
                        type='file'
                        accept={AcceptedFileTypes.join(',')}
                        name={`${name}-input`}
                    />
                    <input
                        id={`${name}-input-mobile`}
                        className='d-none'
                        ref={mobilePictureRef}
                        onChange={handleFileSelection}
                        type='file'
                        capture='environment'
                        accept={AcceptedFileTypes.join(',')}
                        name={`${name}-input-mobile`}
                    />
                    <div className=''>
                        {inputModel === 'file' ? (
                            <TextInput
                                disabled
                                readOnly
                                type='text'
                                placeholder={placeholder}
                                className={className}
                                value={value?.name || ''}
                            />
                        ) : (
                            <FileInputPhotoID
                                value={value}
                                loading={loading}
                                selectedFile={selectedFile}
                                handleRemove={handleRemoveFile}
                                handleTakePicture={handleTakePicture}
                                handleClick={handleUploadClick}
                                className={className}
                            />
                        )}
                    </div>
                </Col>
                {inputModel === 'file' ? (
                    <Col
                        lg={value ? 4 : 1}
                        md={value ? 6 : 1}
                        xs={value ? 12 : 2}
                        className='text-left text-md-start'
                    >
                        {getEventComponent()}
                    </Col>
                ) : null}
            </Row>
            {error && <div className='text-danger'>{error}</div>}
        </>
    );
};

export default FileInput;
