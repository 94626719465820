import React from 'react';
import { Control, Controller, RegisterOptions } from 'react-hook-form';
import InputRadio from '@common/inputs/RadioButtons';
import useAutoSave from '@hooks/useAutoSave';
import FormError from '@components/form/FormErrorComponent';

type RadioOptions = {
    label: React.ReactNode | string;
    value: string;
};

export type FormRadioGroupProps = {
    control: Control<any>;
    name: string;
    options: RadioOptions[];
    className?: string;
    defaultValue?: string;
    rules?: RegisterOptions;
    showError?: boolean;
    disabled?: boolean;
    required?: boolean;
};

const FormRadioGroup = (props: Partial<FormRadioGroupProps>) => {
    const {
        control,
        name = '',
        rules = undefined,
        options,
        disabled,
        required = true,
        defaultValue = '',
        className,
        showError = true,
    } = props;

    const { handleSave } = useAutoSave();
    return (
        <Controller
            name={name}
            key={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <div>
                    {options?.map((option) => (
                        <InputRadio
                            key={option?.value}
                            inline
                            disabled={disabled}
                            onChange={e => {
                                onChange(e);
                                if (!invalid) {
                                    handleSave({ name, value: e.currentTarget.value });
                                }
                            }}
                            className={required && error
                                ? className
                                    ? className.concat(' required-with-error')
                                    : 'required-with-error'
                                : className}
                            label={option?.label}
                            name={name}
                            id={`inline-${name}-${option.label}-1`}
                            value={option.value}
                            checked={value ? option.value === value : option.value === defaultValue}
                        />
                    ))}
                    {showError && error?.message ? <FormError errorMsg="Please make a selection" /> : null}
                </div>
            )}
        />
    );
};

export default FormRadioGroup;
